import React, { Component } from 'react';
import HistoryCard from '../HistoryCard';
import axios from 'axios';


class Historial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchingkey: '',
            notsearching: true,
            federados: [
                {
                    name:"SANTIAGO DOMINGUEZ NAVARRO",
                    sexo:"Masculino",
                    dni:"34022490Q",
                    nacimiento:"1965-12-12",
                    licencia:52,
                    historial:[
                        "2022-01-01",
                        "2018-01-01",
                        "2017-01-01",
                        "2015-01-01",
                    ]
                },
                {
                    name:"Segundo DOMINGUEZ NAVARRO",
                    sexo:"Masculino",
                    dni:"34022490j",
                    nacimiento:"1965-12-12",
                    licencia:53,
                    historial:[
                        "2022-01-01",
                        "2018-01-01",
                        "2017-01-01",
                        "2015-01-01",
                    ]
                },
                {
                    name:"Tercero DOMINGUEZ NAVARRO",
                    sexo:"Masculino",
                    dni:"34022490x",
                    nacimiento:"1965-12-12",
                    licencia:54,
                    historial:[
                        "2022-01-01",
                        "2018-01-01",
                        "2017-01-01",
                        "2015-01-01",
                    ]
                },
                {
                    name:"Cuarto DOMINGUEZ NAVARRO",
                    sexo:"Masculino",
                    dni:"34022490l",
                    nacimiento:"1965-12-12",
                    licencia:55,
                    historial:[
                        "2022-01-01",
                        "2018-01-01",
                        "2017-01-01",
                        "2015-01-01",
                    ]
                }
            ],
            renderFederados: []
        }
        this.getValue = this.getValue.bind(this);
    }
    changeView = (window_tab, state, param) => {
        this.setState({
            [window_tab]: { status: state, param: param }
        })
    }
    getValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.search(event.target.value)
    }
    search = (cadena)=>{
        cadena = cadena.toLowerCase();
        let list = [];
        
        this.state.federados.map((user)=>{
            
            let name = user.name.toLowerCase();
            let licencia = user.licencia;
            let dni = user.dni.toLowerCase();
            if(name.indexOf(cadena)!==-1 || licencia.toString()===cadena ||  dni === cadena){
                list.push(user)
            }
            
        });
        this.setState({
            renderFederados: list,
            notsearching: (cadena===''?true:false)
        });
    }
    
    render() {
        return (
            <div className="content_window">

                <div className="center_column">
                    <div className="full_width floating_left">
                        <input type='text' onChange={this.getValue} name='searchingkey' placeholder='Buscar federado por licencia o nombre' className="search_input floating_left" />
                    </div>
                    <h3 className="spacer floating_left">{this.state.notsearching?'Usuarios':'Resultado de búsqueda'}: {this.state.renderFederados.length}</h3>
                    {
                        this.state.renderFederados.map((user, key) => {
                            
                            return (
                                <HistoryCard
                                    key={key}
                                    usuario={user}
                                    modifyHandler={this.changeView}
                                />
                            )
                            
                        })
                    }
                    <div className="full_width floating_left spacer">
                    </div>
                </div>
            </div>
        );
    }
    load=()=>{
        this.setState({
            renderFederados: this.state.federados
        })
        
        const url = this.props.server + '/historial';
        axios.put(url, {withCredentials: true}).then((res)=>{
            if(res.data.status===200){
                this.setState({
                    federados: res.data.users,
                    renderFederados: res.data.users,
                })
            }
        }).catch(()=>{
            window.alert("Ha ocurrido un problema de conexión");
        })
    }
    componentDidMount() {
        this.load();
    }
}



export default Historial;